<template>
  <b-table
    :data="computedData"
    :paginated="false"
  >
    <b-table-column
      v-slot="props"
      field="operator.name"
      :label="$t('operators.sg')"
    >
      {{ props.row.operator.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="number"
      :label="$t('failures.props.number')"
    >
      <router-link
        :to="{ name: 'failure', params: { id: props.row.id }}"
      >
        {{ props.row.number }}
      </router-link>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="name"
      :label="$t('failures.props.name')"
    >
      {{ props.row.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="state"
      :label="$t('failures.props.state')"
    >
      {{ props.row.state }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="reported_at"
      label="Reported date"
    >
      {{ props.row.reported_at }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="progress.estimated.at"
      label="Estimated date"
    >
      {{ props.row.progress.estimated.at }}
    </b-table-column>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

const columns = [
  {
    field: 'operator.name',
    label: 'Operator'
  },
  {
    field: 'number',
    label: 'Number'
  },
  {
    field: 'name',
    label: 'Description'
  },
  {
    field: 'state',
    label: 'State'
  },
  {
    field: 'reported_at',
    label: 'Reported at'
  },
  {
    field: 'progress.estimated.at',
    label: 'Estimated at'
  }
]

export default {
  data() {
    return {
      columns
    }
  },
  computed: {
    ...mapGetters({
      items: 'failures/reportedNotEstimated'
      // items: 'failures/items',
    }),
    computedData() {
      let today = moment()
      return _.orderBy(this.items.map(item => {
        item.reported_at = moment(item.progress.reported.at).format('LL')
        return item
      }), ['progress.reported.at'], ['desc'])
    }
  },
  async created () {
    await this.$store.dispatch('failures/fetchAndAdd', { where: [['state', '==', 'reported'], ['progress.estimated.at', '==', null]], limit: 0 })
    // this.$store.dispatch('failures/openDBChannel', { where: [['state', '==', 'reported']] })

  },
  methods: {
    /*rowClass(item) {
      if(item.diff < 1) {
        return 'has-text-danger'
      } else if(item.diff < 8) {
        return 'has-text-warning'
      } else {
        return ''
      }
    }*/
  }
}
</script>
