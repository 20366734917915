<template>
  <div>
    <b-tabs>
      <b-tab-item
        v-if="true"
        :label="$t('dashboard.tabs.upcoming')"
        icon="alert"
      >
        <estimated />
      </b-tab-item>
      <b-tab-item
        v-if="isAdmin"
        :label="$t('dashboard.tabs.approval_needed')"
        icon="account-check"
      >
        <reported />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Reported from './Dashboard/Reported'
import Estimated from './Dashboard/Estimated'

export default {
  components: {
    Reported,
    Estimated
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin'
    })
  }
}
</script>
