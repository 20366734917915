<template>
  <div>
    <div
      class="is-pulled-right is-flex buttons"
    >
      <a-select
        :manual-doc="userDoc"
        field="routes.dashboard.estimated.upcomingLimit"
        :label="$t('dashboard.estimated.upcomingLimit')"
        :options="keys(upcomingLimitOptions)"
        :labels="upcomingLimitOptions"
        @change="streamUpcomingFailures"
      />
    </div>
    <div class="is-clearfix" />

    <b-table
      :data="computedData"
      :row-class="rowClass"
      :paginated="false"
    >
      <b-table-column
        v-slot="props"
        field="operator.name"
        :label="$t('operators.sg')"
      >
        {{ props.row.operator.name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="number"
        :label="$t('failures.props.number')"
      >
        <router-link
          :to="{ name: 'failure', params: { id: props.row.id }}"
        >
          {{ props.row.number }}
        </router-link>
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="name"
        :label="$t('failures.props.name')"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="state"
        :label="$t('failures.props.state')"
      >
        {{ props.row.state }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="reported_at"
        label="Reported date"
      >
        {{ props.row.reported_at }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="reported_diff"
        label="Days in service"
      >
        {{ props.row.reported_diff }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="estimated_at"
        label="Estimated date"
      >
        {{ props.row.estimated_at }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="estimated_diff"
        label="Days to deadline"
      >
        {{ props.row.estimated_diff }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { orderBy, keys } from 'lodash'

export default {
  data() {
    return {
      upcomingLimitOptions: {
        '30': '30 days',
        '60': '60 days',
        '90': '90 days',
        '': 'All unrepaired'
      },
      userDoc: { module: 'user' }
    }
  },
  computed: {
    ...mapGetters({
      items: 'failures/upcomingDeadline'
    }),
    computedData() {
      let today = moment()
      return orderBy(this.items.map(item => {
        // console.log(typeof item.progress.estimated.at, item.progress.estimated.at.toString())
        let estimated_at = item.progress.estimated.at ? moment(item.progress.estimated.at) : null
        if(estimated_at) {
          item.estimated_at = estimated_at.format('LL')
          item.estimated_diff = estimated_at.diff(moment(), 'days')
        } else {
          item.estimated_at = null
          item.estimated_diff = null
        }

        let reported_at = item.progress.reported.at ? moment(item.progress.reported.at) : null
        if(reported_at) {
          item.reported_at = reported_at.format('LL')
          item.reported_diff = Math.abs(reported_at.diff(moment(), 'days'))
        } else {
          item.reported_at = null
          item.reported_diff = null
        }

        return item
      }), ['estimated_diff'], ['desc'])
    },
    settings () {
      return this.user.routes.dashboard.estimated
    }
  },
  created () {
    this.streamUpcomingFailures()
  },
  methods: {
    keys,
    rowClass(item) {
      if(item.estimated_diff < 1) {
        return 'has-text-danger'
      } else if(item.estimated_diff < 8) {
        return 'has-text-warning'
      } else {
        return ''
      }
    },
    streamUpcomingFailures() {
      let where = [
        ['state', 'in', ['reported']]
      ]

      if(this.settings.upcomingLimit) {
        where.push(['progress.estimated.at', '<', moment().add(this.settings.upcomingLimit, 'days').toDate()])
      } else {
        where.push(['progress.estimated.at', '<', moment().add(999, 'days').toDate()])
      }

      this.$store.dispatch('failures/fetchAndAdd', { where, limit: 0 })
    }
  }
}
</script>
